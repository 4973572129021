import React, { useEffect, useState } from 'react';
import { Styles } from './styles';
import Link from 'next/link';
import Image from 'next/image';
import { Col, Container, Row } from 'react-bootstrap';

const StickyMenuTemplate: React.FC = () => {
  const [scroll, setScrool] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 160) {
        setScrool('sticky');
      } else {
        setScrool('');
      }
    });
  }, []);

  return (
    <Styles>
      {/* Sticky Menu */}
      <section className={`sticky-menu ${scroll}`}>
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link href="/">
                  <Image width={200} height={57} src="/images/logo_dark.svg" alt="Logo" />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" href="/cursos" data-toggle="dropdown">
                      Cursos
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link className="nav-link" href="/sobre" data-toggle="dropdown">
                      Quem Somos
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link className="nav-link" href="/contato" data-toggle="dropdown">
                      Contato
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default StickyMenuTemplate;
