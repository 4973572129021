import React from 'react';
import FooterTemplate from './template';

const Footer = () => {
  return (
    <FooterTemplate
      logo="/images/logo_light.svg"
      address="Av. Barão do Rio Branco, 3480, Quinto Andar, Altos Passos, Juiz de Fora - MG"
      facebookUrl="https://www.facebook.com/App-Masters-1389727984407110/"
      instagramUrl="https://www.instagram.com/appmasters.io/"
      linkedinUrl="https://br.linkedin.com/company/appmasters.io"
      websiteUrl="https://appmasters.io"
      libraryDescription="Conheça a biblioteca pública da App Masters, com livros de programação e tecnologia."
      libraryUrl="/biblioteca"
    />
  );
};

export default Footer;
