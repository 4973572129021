/* eslint-disable @next/next/no-img-element */
import { NotFoundWrapper } from './ErrorView.styles';
import Typography from '../../components/ui/Typography';
import { VscError } from 'react-icons/vsc';
import Button from '../../components/ui/Button';
import Link from 'next/link';
import { useEffect } from 'react';
import rollbar from '../../utils/rollbar';

type Props = {
  statusCode?: number;
  error?: Error | null;
};

const ErrorView: React.FC<Props> = ({ error, statusCode }) => {
  useEffect(() => {
    if (statusCode !== 404)
      rollbar.error(`[${statusCode}] User on the error page`, {
        error: JSON.stringify(error),
      });
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <NotFoundWrapper>
        <img src={'/new-breadcrumb.png'} alt="Blue gradient" />
        <div>
          <div>
            <VscError size={100} />
          </div>
          <br />
          <Typography variant="h2" color="white" fontWeight="600">
            "Desculpe, houve um erro inesperado, mas nosso time já foi avisado e vamos corrigir em
            breve"
          </Typography>
          <br />

          <Link href="/">
            <Button>Voltar para página inicial</Button>
          </Link>
        </div>
      </NotFoundWrapper>
    </div>
  );
};

export default ErrorView;
