type StringMap = {
  [key: string]: string;
};

export const CoursesCategoryMap: StringMap = {
  backend: 'Backend',
  frontend: 'Frontend',
  web: 'Web',
  mobile: 'Mobile',
};

export const CoursesSubscriptionTypeMap: StringMap = {
  free: 'Gratuito',
  paid: 'Pago',
};

export const CoursesSkillLevelMap: StringMap = {
  beginner: 'Iniciante',
  intermediate: 'Intermediário',
  advanced: 'Avançado',
};

export const imageConstraints = {
  'Academy-Logo':
    'https://res.cloudinary.com/appmasters-io/image/upload/v1697745213/image_1670685f0c.jpg',
  'Course-Hero':
    'https://res.cloudinary.com/appmasters-io/image/upload/v1697799286/Reuniao_time_videochamada_Crop_copy_e378f28cc8.jpg',
  'Course-Grid-Hero':
    'https://res.cloudinary.com/appmasters-io/image/upload/v1625766147/img_3244_5a638e4362.jpg',
  'Home-Hero':
    'https://res.cloudinary.com/appmasters-io/image/upload/v1697798902/969_A8822_ab15fd0ba2.jpg',
  'Contact-Hero':
    'https://res.cloudinary.com/appmasters-io/image/upload/v1697799631/969_A8872_copy_b1df16f63f.jpg',
  'Not-Found-Page-Background':
    'https://res.cloudinary.com/appmasters-io/image/upload/v1697798902/969_A8822_ab15fd0ba2.jpg',
  'Generic-Image':
    'https://res.cloudinary.com/appmasters-io/image/upload/v1697799286/Reuniao_time_videochamada_Crop_copy_e378f28cc8.jpg',
};
