import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../public/assets/css/line-awesome.min.css';
import '../../public/assets/css/animate.css';
import '../../public/assets/css/swiper.min.css';
import '../../public/assets/css/modal-video.min.css';
import 'react-toastify/dist/ReactToastify.css';
import GlobalStyles from '../styles/global';
import theme from '../styles/theme';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { useApollo } from '../utils/apollo';
import Footer from '../components/elements/Footer';
import Header from '../components/elements/Header';
import SEO from '../../next-seo.config';
import { DefaultSeo } from 'next-seo';
import { StyledToast } from '../components/ui/Toast/styles';
import { useRouter } from 'next/router';
import RollbarProvider from '../providers/RollbarProvider';
import { getRollbar } from '../utils/rollbar';
import Script from 'next/script';
import { getPixelIds, pageView } from '../utils/facebook-pixel';

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const router = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const url = window.location.href;
      if (url.includes('http://')) {
        const newUrl = url.replace('http://', 'https://');
        router.push(newUrl, newUrl);
      }
    }
  }, []);

  useEffect(() => {
    pageView();

    const handleRouteChange = () => {
      pageView();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const rollbarInstance = getRollbar();

  const pixelIds = getPixelIds();

  return (
    <>
      <Script
        type="text/javascript"
        id="hs-script-loader"
        defer
        src={`//js.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_TRACKING_ID}.js`}
      />
      <Script
        defer
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="gtag-script" type="text/javascript" defer>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
          });
      `}
      </Script>
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            ${pixelIds.map((id) => `fbq('init', ${id})`).join(';')};
          `,
        }}
      />
      <DefaultSeo {...SEO} />
      <RollbarProvider instance={rollbarInstance}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ApolloProvider client={apolloClient}>
            <Header />
            <Component {...pageProps} />
            <Footer />
            <StyledToast />
          </ApolloProvider>
        </ThemeProvider>
      </RollbarProvider>
    </>
  );
}
export default MyApp;
