import { imageConstraints } from './src/utils/constraints';

export default {
  title: 'App Masters Academy',
  description:
    'Plataforma de cursos para desenvolvimento web e mobile criada pela empresa App Masters',
  openGraph: {
    type: 'website',
    locale: 'pt',
    url: process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3000',
    ['site_name']: 'App Masters Academy',
    images: [
      {
        url: imageConstraints['Academy-Logo'],
        width: 800,
        height: 600,
        alt: 'Logo da App Masters Academy',
      },
    ],
  },
};
