import styled from 'styled-components';
import { colors } from '../../../styles/colors';

export const Styles = styled.div`
  footer.footer1 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: 55px 0 55px;

    &:before {
      position: absolute;
      content: '';
      background: ${colors.bg1};
      opacity: 0.98;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .footer-logo-info {
      img {
        margin-bottom: 30px;

        @media (max-width: 575px) {
          margin-bottom: 20px;
        }
      }

      p {
        font-size: 14px;
        color: ${colors.text3};
        line-height: 28px;
        margin-bottom: 20px;
      }

      ul {
        li {
          color: ${colors.text3};
          margin-bottom: 12px;

          svg {
            font-size: 18px;
            color: ${colors.blue2};
            width: 35px;
            vertical-align: top;
          }
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }

    .f-links {
      width: 100%;
      h5 {
        color: ${colors.border1};
        text-transform: uppercase;
        margin-top: 8px;
        margin-bottom: 35px;

        @media (max-width: 575px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }
      p {
        color: ${colors.text3};
      }

      ul {
        float: left;
        width: 50%;

        li {
          a {
            font-size: 14px;
            color: ${colors.text3};
            line-height: 38px;

            svg {
              font-size: 12px;
              color: ${colors.blue2};
              margin-right: 10px;
            }

            &:hover {
              color: ${colors.blue2};
            }

            @media (max-width: 991px) {
              font-size: 13px;
              letter-spacing: 0;
            }
          }
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 20px;
        display: inline-block;
      }
      button.enroll-btn {
        font-size: 14px;
        color: #fff;
        background: ${colors.grBg};
        display: inline-block;
        height: 30px;
        font-weight: 500;
        border: none;
        padding: 6px 10px;
        border-radius: 5px;
        &:hover {
          background: ${colors.grBg2};
        }

        @media (max-width: 575px) {
          font-size: 14px;
        }
      }
    }

    .f-post {
      h5 {
        color: ${colors.border1};
        text-transform: uppercase;
        margin-top: 8px;
        margin-bottom: 45px;

        @media (max-width: 575px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }

      .post-box {
        margin-bottom: 12px;

        .post-img {
          img {
            max-width: 80px;
            border-radius: 5px;
            margin-right: 15px;
          }
        }

        .post-content {
          a {
            font-size: 14px;
            color: ${colors.text3};
            display: inline-block;
            margin-bottom: 10px;

            &:hover {
              color: ${colors.blue2};
            }

            @media (max-width: 991px) {
              font-size: 13px;
              letter-spacing: 0;
              margin-bottom: 0;
            }
          }

          span {
            display: block;
            color: ${colors.text2};
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding: 60px 0 20px;
    }
  }

  .copyright-area {
    padding: 35px 0 38px;
    background: ${colors.copyBg};

    .responsive-flex {
      display: flex;
      @media (max-width: 767px) {
        flex-direction: column !important;

        .social-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .copy-text {
      p {
        color: ${colors.text3};
        padding-top: 3px;

        svg {
          color: ${colors.blue2};
          margin: 0 2px;
        }

        a {
          color: ${colors.blue2};

          &:hover {
            text-decoration: underline;
          }
        }
      }

      @media (max-width: 767px) {
        text-align: center;
        margin-bottom: 20px;

        .responsive-flex {
          flex-direction: column !important;
          align-items: center;
          justify-content: center;
          outline: 2px solid blue;
        }
      }
    }
  }
`;
