import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { colors } from '../../../styles/colors';

export const StyledToast = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast--error {
    background-color: ${colors.red};
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: ${colors.blue};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
