import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Styles } from './styles';
import Link from 'next/link';
import Image from 'next/image';
import StickyMenu from '../StickyMenu/template';
import MobileMenu from '../MobileMenu';

const HeaderTemplate = () => {
  return (
    <Styles>
      {/* Logo Area 2 */}
      <section className="logo-area2">
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link href="/">
                  <Image width={200} height={57} src={'/images/logo_dark.svg'} alt="Logo" />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" href="/cursos" data-toggle="dropdown">
                      Cursos
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link className="nav-link" href="/sobre" data-toggle="dropdown">
                      Quem Somos
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link className="nav-link" href="/contato" data-toggle="dropdown">
                      Contato
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Sticky Menu */}
      <StickyMenu />

      {/* Mobile Menu */}
      <MobileMenu />
    </Styles>
  );
};

export default HeaderTemplate;
