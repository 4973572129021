import React from 'react';
import { Styles } from './styles';
import Link from 'next/link';
import {
  FaFacebookF,
  FaGlobe,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

export type SocialMediasProps = {
  facebookUrl?: string;
  twitterUrl?: string;
  linkedinUrl?: string;
  youtubeUrl?: string;
  instagramUrl?: string;
  websiteUrl?: string;
  url?: string;
  title?: string;
};

const SocialMedias: React.FC<SocialMediasProps> = ({
  facebookUrl,
  linkedinUrl,
  instagramUrl,
  youtubeUrl,
  twitterUrl,
  websiteUrl,
  url,
  title,
}) => {
  const hasSocial = facebookUrl || twitterUrl || linkedinUrl || youtubeUrl || instagramUrl || url;
  return hasSocial ? (
    <Styles>
      <ul className="social list-unstyled list-inline">
        {facebookUrl && !url && (
          <li className="list-inline-item facebook">
            <Link target="_blank" href={facebookUrl}>
              <FaFacebookF />
            </Link>
          </li>
        )}
        {twitterUrl && !url && (
          <li className="list-inline-item twitter">
            <Link target="_blank" href={twitterUrl}>
              <FaTwitter />
            </Link>
          </li>
        )}
        {instagramUrl && !url && (
          <li className="list-inline-item instagram">
            <Link target="_blank" href={instagramUrl}>
              <FaInstagram />
            </Link>
          </li>
        )}
        {linkedinUrl && !url && (
          <li className="list-inline-item linkedin">
            <Link target="_blank" href={linkedinUrl}>
              <FaLinkedin />
            </Link>
          </li>
        )}
        {youtubeUrl && !url && (
          <li className="list-inline-item youtube">
            <Link target="_blank" href={youtubeUrl}>
              <FaYoutube />
            </Link>
          </li>
        )}
        {websiteUrl && !url && (
          <li className="list-inline-item website">
            <Link target="_blank" href={websiteUrl}>
              <FaGlobe />
            </Link>
          </li>
        )}

        {url && (
          <li className="list-inline-item facebook">
            <FacebookShareButton
              placeholder=""
              style={{ backgroundColor: '#4267b2', height: '16px' }}
              url={url}
              quote={title}
            >
              <FaFacebookF />
            </FacebookShareButton>
          </li>
        )}
        {url && (
          <li className="list-inline-item twitter">
            <TwitterShareButton
              placeholder=""
              style={{ backgroundColor: '#1da1f2', height: '16px' }}
              url={url}
              title={title}
            >
              <FaTwitter />
            </TwitterShareButton>
          </li>
        )}
        {url && (
          <li className="list-inline-item linkedin">
            <LinkedinShareButton
              placeholder=""
              style={{ backgroundColor: '#2867b2', height: '16px' }}
              url={url}
            >
              <FaLinkedin />
            </LinkedinShareButton>
          </li>
        )}
      </ul>
    </Styles>
  ) : (
    <></>
  );
};

export default SocialMedias;
