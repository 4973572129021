import { useState, useEffect, useMemo } from 'react';

// This object is only needed here so it really doesn't need to be exported or exist in another file
// If you want to access the screen size you would use the media function, the one in this file, anyway
const screens = {
  extraSmallest: '375px',
  extraSmall: '460px',
  small: '576px',
  medium: '768px',
  mediumPlus: '940px',
  large: '992px',
  extraLarge: '1200px',
  extraLarger: '1440px',
  extraLargest: '1920px',
  megaLarge: '2000px',
};

const media = (min: keyof typeof screens, max?: keyof typeof screens | boolean) => {
  if (typeof max === 'boolean') return `@media screen and (max-width:${screens[min]})`;
  if (!max) return `@media screen and (min-width:${screens[min]})`;
  return `@media screen and (min-width:${screens[min]}) and (max-width:${screens[max]})`;
};

export default media;

/**
 * Hook for using media queries inside the react components
 */
export const useMedia = (min: keyof typeof screens, max?: keyof typeof screens | boolean) => {
  const minWidth = useMemo(
    () => (max !== true ? Number(screens[min].replace('px', '')) : undefined),
    [max, min],
  );
  const maxWidth = useMemo(
    () =>
      max === true
        ? Number(screens[min].replace('px', ''))
        : max
          ? Number(screens[max].replace('px', ''))
          : undefined,
    [max, min],
  );

  const existsWindow = typeof window !== 'undefined';
  const [width, setWidth] = useState(existsWindow ? window.innerWidth : 1200);

  useEffect(() => {
    const handleWindowResize = () => {
      if (existsWindow) setWidth(window.innerWidth);
    };

    if (existsWindow) window.addEventListener('resize', handleWindowResize);
    return () => {
      if (existsWindow) window.removeEventListener('resize', handleWindowResize);
    };
  }, [existsWindow]);

  return [
    (!maxWidth || (maxWidth && width < maxWidth)) && (!minWidth || (minWidth && width > minWidth)),
    width,
  ];
};
