import { colors } from './colors';
import { fonts } from './fonts';

const theme = {
  name: 'Default',
  colors,
  fonts,
};

export default theme;

export type AppTheme = typeof theme;
