import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Styles } from './styles';
import Link from 'next/link';
import { FaAngleRight, FaMapMarkerAlt } from 'react-icons/fa';
import Image from 'next/image';
import SocialMedias from '../../ui/SocialMedias';

type FooterProps = {
  logo?: string;
  address?: string;
  facebookUrl?: string;
  twitterUrl?: string;
  linkedinUrl?: string;
  youtubeUrl?: string;
  instagramUrl?: string;
  websiteUrl?: string;
  libraryUrl?: string;
  libraryDescription?: string;
};

const FooterTemplate: React.FC<FooterProps> = ({
  logo,
  address,
  facebookUrl,
  twitterUrl,
  linkedinUrl,
  youtubeUrl,
  instagramUrl,
  websiteUrl,
}) => {
  return (
    <Styles>
      {/* Footer Area */}
      <footer className="footer1">
        <Container>
          <Row>
            <Col>
              {logo && <Image src={logo} width={200} height={57} alt="Logo" />}
              <div className="footer-logo-info">
                <ul className="list-unstyled mt-3">
                  <li>
                    {address && (
                      <span>
                        <FaMapMarkerAlt /> {address}
                      </span>
                    )}
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="3">
              <div className="f-links">
                <h5 className="mb-2">LINKS ÚTEIS</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link href="/cursos">
                      <FaAngleRight />
                      Cursos
                    </Link>
                  </li>
                  <li>
                    <Link href="/sobre">
                      <FaAngleRight />
                      Quem Somos
                    </Link>
                  </li>
                  <li>
                    <Link href="/contato">
                      <FaAngleRight />
                      Contato
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      {/* Copyright Area */}
      <section className="copyright-area">
        <Container>
          <div className="responsive-flex">
            <Col md="6">
              <div className="copy-text">
                {websiteUrl && (
                  <p>
                    Da &nbsp;
                    <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
                      App Masters
                    </a>
                    &nbsp; para você.
                  </p>
                )}
              </div>
            </Col>
            <Col md="6" className="text-right social-container">
              <SocialMedias
                {...{ facebookUrl, twitterUrl, linkedinUrl, youtubeUrl, instagramUrl, websiteUrl }}
              />
            </Col>
          </div>
        </Container>
      </section>
    </Styles>
  );
};

export default FooterTemplate;
