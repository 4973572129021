type Event = 'AddToCart' | 'InitiateCheckout' | 'Purchase';

export const getPixelIds = () => {
  const pixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_IDS || '';
  const ids = pixelId.split(',');
  return ids || [];
};

export const pageView = () => window.fbq('track', 'PageView');

export const trackPixelEvent = (event: Event) => {
  window.fbq('track', event);
};
