import styled from 'styled-components';
import { colors } from '../../../styles/colors';

export const Styles = styled.div`
  ul.social {
    li {
      a,
      button {
        text-align: center;
        position: relative;

        display: flex;
        align-items: center;
        background-color: #4267b2;
        height: 16px;

        &:before {
          content: '';
          position: absolute;
          border-width: 8px 14px;
          border-style: solid;
          border-top-color: transparent;
          border-right-color: transparent;
          border-left-color: transparent;
          top: -16px;
          left: 0;
          z-index: 1;
          transition: all 0.2s ease;
        }

        &:after {
          content: '';
          position: absolute;
          border-width: 8px 14px;
          border-style: solid;
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
          bottom: -16px;
          left: 0;
          z-index: 1;
          transition: all 0.2s ease;
        }

        svg {
          font-size: 13px;
          color: #ffffff;
          width: 28px;
        }

        &:hover {
          background-color: ${colors.blue2} !important;

          &:before {
            border-bottom-color: ${colors.blue2} !important;
          }

          &:after {
            border-top-color: ${colors.blue2} !important;
          }
        }
      }

      &.facebook {
        a,
        button {
          &:before {
            border-bottom-color: #4267b2;
          }

          &:after {
            border-top-color: #4267b2;
          }
        }
      }

      &.twitter {
        a,
        button {
          background-color: #1da1f2;

          &:before {
            border-bottom-color: #1da1f2;
          }

          &:after {
            border-top-color: #1da1f2;
          }
        }
      }

      &.linkedin {
        a,
        button {
          background-color: #2867b2;

          &:before {
            border-bottom-color: #2867b2;
          }

          &:after {
            border-top-color: #2867b2;
          }
        }
      }

      &.youtube {
        a {
          background-color: #dd1343;

          &:before {
            border-bottom-color: #dd1343;
          }

          &:after {
            border-top-color: #dd1343;
          }
        }
      }
      &.website {
        a {
          background-color: #ea4c89;

          &:before {
            border-bottom-color: #ea4c89;
          }

          &:after {
            border-top-color: #ea4c89;
          }
        }
      }
      &.instagram {
        a {
          background-color: #a15c20;

          &:before {
            border-bottom-color: #a15c20;
          }

          &:after {
            border-top-color: #a15c20;
          }
        }
      }
    }
  }
`;
