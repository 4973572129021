import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Styles } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import { FaBars, FaTimes } from 'react-icons/fa';

const MobileMenuTemplate = () => {
  const router = useRouter();
  const [openSideMenu, setOpenSideMenu] = useState(false);

  return (
    <Styles>
      {/* Mobile Menu */}
      <section className="mobile-menu-area">
        <Container>
          <Row>
            <Col md={0} sm={12}>
              <div className="mb-logo-area d-flex justify-content-between">
                <div className="mb-logo-box d-flex">
                  <div className="hm-button">
                    <button
                      className="bg-transparent border-0"
                      onClick={() => setOpenSideMenu(true)}
                      id="mb-sidebar-btn"
                    >
                      <FaBars />
                    </button>
                  </div>
                  <div className="mb-logo">
                    <Link href="/">
                      <Image width={150} height={43} src="/images/logo_light.svg" alt="Logo" />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mobile Menu Sidebar */}
      <section className={`mb-sidebar ${openSideMenu ? 'opened' : ''}`} id="mb-sidebar-body">
        <div className="mb-sidebar-heading d-flex justify-content-between">
          <div>
            <h5>Menu</h5>
          </div>
          <div>
            <button
              className="bg-transparent border-0"
              onClick={() => setOpenSideMenu(false)}
              id="close-mb-sidebar"
            >
              <FaTimes />
            </button>
          </div>
        </div>
        <div className="mb-sidebar-menu">
          <div className="mb-menu-item">
            <button className="mb-menu-button active" onClick={() => router.push('/cursos')}>
              Cursos
            </button>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button active" onClick={() => router.push('/sobre')}>
              Quem Somos
            </button>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button active" onClick={() => router.push('/contato')}>
              Contato
            </button>
          </div>
        </div>
      </section>
      <div
        className={`mb-sidebar-overlay ${openSideMenu ? 'visible' : ''}`}
        id="mb-sidebar-overlay"
      ></div>
    </Styles>
  );
};

export default MobileMenuTemplate;
