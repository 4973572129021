type Colors = {
  bg1: string;
  bg2: string;
  grBg: string;
  grBg2: string;
  copyBg: string;
  blue: string;
  blue2: string;
  blue3: string;
  red: string;
  purple: string;
  yellow: string;
  black1: string;
  black2: string;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  border1: string;
  border2: string;
  border3: string;
  footer1: string;
  footer2: string;
  ftext: string;
  white: string;
};

export const colors: Colors = {
  bg1: '#182B49',
  bg2: '#F6F9FF',
  grBg: 'linear-gradient(90deg, #2c97ea 0%, #2C90C3 100%)',
  grBg2: 'linear-gradient(90deg, #2C90C3 0%, #2c97ea 100%)',
  copyBg: '#122340',
  blue: '#2c97ea',
  blue2: '#2C90C3',
  blue3: '#00b4ff',
  red: '#ff6f6f',
  purple: '#84479c',
  yellow: '#fbab19',
  black1: '#182B49',
  black2: '#444444',
  text1: '#555555',
  text2: '#666666',
  text3: '#969696',
  text4: '#aaaaaa',
  text5: '#cccccc',
  border1: '#eeeeee',
  border2: '#3e3e3e',
  border3: '#dddddd',
  footer1: '#1a1b25',
  footer2: '#16171f',
  ftext: '#8D8E92',
  white: '#ffffff',
};
